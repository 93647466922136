import React from 'react'
import Navigation from '../components/Navigation';
import { useSelector } from 'react-redux';

// languageChanger == 'Ru'

export default function Year2022() {
  const languageChanger = useSelector((state) => state.language);
  const year2022Header =
    languageChanger !== 'Eng'
      ? "How we woke up in a different country."
      : "Как мы проснулись в другой стране."
  const year2022Description =
    languageChanger !== 'Eng'
      ? {
        p1: "Greetings to all. It is time to write my opinion on what happened in 2022.",
        p2: "It makes little sense to go deep into history, since today each of us can replenish or acquire knowledge regarding the history, geography and statistics of our country. Little note. I wrote the words “our country” and immediately doubted it, because it feels like the country of the current “top” and they dispose it of their own discretion. Since 2022, they have been managing lives and health of ordinary people.",
        p3: "Of course, in February i didn’t expect what has happened and was categorically against the war right away. At that time to call war as war and be against it was not yet illegal. The attack on the fraternal people and the war with them seems to be sheer absurdity and senseless, but this has all been going on for more than a year right before our eyes. Summarizing information from various sources and drawing a median, we can say that about half a million people had already died including the Ukranian military, Russian military and civilians in both countries. About 10 million people have left their countries. To be fair, noticeably more people left Ukraine, saving their lives from rockets and mortars. From Russia - from the possibility of being convicted for their views or being called to kill. But there was no need to protect anything! Nobody attacked Russia.",
        p4: "What was and is being said about the reasons for the attack in Russian information sources:",
        p5: "1. “In Ukraine, there are Banderites, fascists, satanists, etc.” Even if it is true, how can this concern Russia? Ukraine is a different country. They did not ask for help or military intervention.",
        p6: "2. “There was a threat of an attack on Russia. Russia attacked first to minimize losses.“ Let me remind you that Russia is a country with nuclear weapons. How it can be consciously attacked? History knows at least one case when the nearest neighbor, smaller and economically weaker at that time, attacked a larger neighbor who has nuclear weapons in great numbers?!",
        p7: "3. “Russian people interest protection in the the territories of the Donetsk and Lugansk regions.“ But if you need to protect Russians outside of Russia, then why not just give them the opportunity to come to live in such a huge country instead of a war?! To give passports with little of bureaucracy and free housing at least for some period of time. What could prevent the government from making such a decision? There is another question. If Russian interests protection was the reason of Donetsk and Lugansk invasion, then why Russian government did campaign against Kyiv, took Kherson and Zaporozhye? ln other words, two regions were announced, but two more were grabbed by military seizure of territories and pseudo-voting. Elon Musk suggested much better voting solution, where both sides of the conflict and NATO representatives should be present. At gunpoint or just standing next to armed men, would you sign a ballot for what you were told to sign?",
        p8: "There were some other arguments about the reasons to start so called special military operation, or, to put it bluntly, Russia’s invasion in Ukraine. But those arguments are even more dubious.",
        p9: "If everything that the Russian authorities and state media told about the reasons for the military invasion was “neither here nor there”, then what are the true ones?",
        p10: "1. “Invasion keeps the power in hands of the current government”. No doubts about it. A common enemy unites and distracts from issues within the country. Someone is now talking in Russia about not built schools, kindergartens, indexation of salaries, embezzled (disbursed) state money, etc?!",
        p11: "2. “An attempt at military subjugation of the neighboring country”, which failed. If the military invasion had been limited to Donetsk and Lugansk regions, as it was originally announced, then there would probably be no talk of an armed seizure of territories.",
        p12: "3. ′′The economic downturn is now easily explained” by sanctions. It is true, but, simply, do not attack your neighbor and there will be no sanctions.",
        p13: "But if not to attack, then how to explain the lack of living standards, own production and innovative technologies? Of course, somewhere you could have seen in Russia “full-cycle production”. But mechanisms were brought from Europe, components from China, they glued the label “made in Russia” and after that such product was really made in Russia, right?!",
        p14: "It would be funny if it wasn’t so sad.",
        p16: "Now, thanks to Putin and team, there are only two points of view in the country: state and incorrect. If you do not support the military invasion (special operation) and have a large audience, you are definitely a foreign agent. Yuri Dud, who enlightened everyone in Russia about the deadly disease (AIDS) is a foreign agent. He interviewed those whom he considered important to do so. What is wrong with that? He did not say a word against the authorities, but earned the title. Semyon Slepakov, who addressed the president publicly with one question, did not offend anyone, is a foreign agent and left the country. Alla Pugacheva - an era - left the country. Maxim Galkin, Andrey Makarevich, Noize MC, Zemfira, Alexey Pivovarov, Artur Smolyanlnov… are all left the country.",
        p17: "So, you wake up in the morning and it feels like some kind of a bad dream: the war with Ukraine, all your heroes are foreign agents, the government is taking limitedly fit lT people to the war, the West is the enemy. It should also be added here that you yourself are an idiot and then everything would fit together. So, you go, drink coffee, gradually comes the confidence that this is not a dream. At this moment, you really want to wake up. It seems that the city is the same, the people outside the window are the same, the weather is the same, visually the same president that was 10 years ago, but this is a different country!",
        p18: "Write only bad things about anyone endlessly is not polite, but i have to mention the retirement age increase in Russia. They nodded in the direction that this action is the same as in the west. The essential difference is that, for instance, the average life expectancy for men in France is 80 years, while in Russia is no more than 65. It turns out that in Russia you make pension contributions that you will never receive. Another comparison. The UAE government buys housing (usually a house) and pays for education with oil revenues for each of its residents. If there is a desire and ability of a resident, the government pays for education abroad. Those are too much contrasting actions: to help citizens in all the important basics (UAE) and to take away pensions from pre-pensioners (Russia). In both countries, the main source of income is oil production. In the UAE, however, now tourism is in the lead, but these are details. The country rose up on oil. Russia still stands on it today. To be precise, not Russia, but government, their families and corrupted partners only.",
        p19: "It tums out that the latest trends in Russia are: to take away money from pre-retirement aging people and to seize the territory by force from a smaller, fraternal country. Do such trends correspond to your life values, the values of your relatives and friends? All this is happening at a time when there is more money in IT than in the entire oil industry combined. Even though, Yandex was born as a replica of Google, yesterday it was still a progressive, very much developed company. Everyone should go to IT today, but, instead, an aggressive campaign against Ukraine was forcibly “offered”, which has already claimed a huge number of peoples lives. Such unreasonable, unnatural actions were “offered” to a resident of a culturally rich, beautiful, vast country.",
        p20: "The most dissenting are put in jail. Those who partially disagree are subjected to persecution by any means at the discretion of performers. There are several questions. Do the performers have children? What country will they live in? Or is it just pleasant for them now to think that the same methods will not be used on them and their families?",
        p21: "And (…) ",
        p22: "It would be funny if it wasn’t so sad.",
      }
      : {
        p1: "Приветствую всех. Пришло время написать свое мнение о том, что произошло в 2022 году.",
        p2: "Сильно вдаваться в историю нет большого смысла, так как сегодня каждый из нас может восполнить или обрести знания касательно истории, географии и статистики нашей страны. Маленькая ремарка. Написал слово “нашей страны” и как-то засомневался, так как по ощущениям – это страна действующей “верхушки”, которой она распоряжаются на свое личное усмотрение. А с 2022 года распоряжается в т.ч. жизнями и здоровьем обычных людей.",
        p3: "Конечно, в феврале я не ожидал того, что случилось и категорично был сразу против войны. Тогда еще не было незаконным войну называть войной и быть против. Нападение на братский народ и война с ним кажется полнейшим абсурдом и бессмыслицей, но это все продолжается уже более года на наших глазах. Суммируя информацию из разных источников и проведя медиану, можно сказать, что к началу 2023 года уже умерло около полумиллиона человек, включая украинских военных, российских военных и мирное население обоих стран. Около 10 миллионов человек покинули страны. Справедливости ради, из Украины выехало заметно больше людей, спасая свои жизни от ракет и минометов. Из России – от возможности быть осужденным за свои взгляды или быть призванным убивать. При этом защищать-то ничего не нужно! На Россию никто не нападал.",
        p4: "Что говорили и говорят о причинах нападения в российских информационных источниках:",
        p5: "1. “Там, в Украине, бендеровцы, фашисты, сатанисты и т.д.” Даже если это так, то как это может касаться Россию?! Украина – другая страна. Они не просили о помощи или военном вмешательстве.",
        p6: "2. “Была угроза нападения на Россию. Напали первыми, чтобы минимизировать потери.” Напомню, что Росссия – страна с ядерным оружием. Как можно на нее напасть?! История знает хоть один случай, когда ближайший сосед поменьше и экономически на тот момент послабее напал бы на соседа побольше у которого есть ядерное оружие в больших количествах?!",
        p7: "3. “Защита интересов русских на территориях Донецкой и Луганской областей”. Но если нужно защишать русских вне России, то почему бы вместо войны просто не дать возможность им приехать жить в такую огромную страну?! Дать паспорта по ускоренной процедуре и бесплатное жилье на первое время для поиска работы. Что могло помешать правительству принять такое решение? Есть еще вопрос. Если нужно было защищать интересы русских в Донецкой и Луганской, то причем тут Херсон, Запорожская и походы на Киев? Другими словами, озвучили два региона, а хапнули еще два путем военного захвата территорий и проведения псевдоголосования. Нормальное голосование предложил Илон Маск, где должны присутствовать обе стороны конфликта и кто-то из НАТО. Под дулом пистолета или просто стоя рядом с вооруженными людьми, ты бы подписал бюллетень за то, за что тебе сказали подписывать?",
        p8: "Были еще какие-то аргументы о причинах начала СВО или, говоря прямым языком, нападения России на Украину. Но те аргументы еще гораздо более сомнительные.",
        p9: "Если же все, что рассказали российские власти и государственные СМИ о причинах военного вторжения - “не пришей козе баян”, то каковы настоящие причины?",
        p10: "1. ”Вторжение позволяет удерживать власть властьимущим”. Без сомнения. Общий враг объединяет и отвлекает от вопросов внутри страны. Кто-то сейчас говорит в России о непостроенных школах, садах, индексации зарплат, разворованных(освоенных) государственных деньгах и т.д.?!",
        p11: "2. “Попытка военного подчинения соседней страны”, которая не удалась. Если бы военное вторжение ограничилось Донецкой и Луганской, как это было изначально озвучено, то о вооруженном захвате территорий, возможно, речи бы не шло.",
        p12: "3. “Экономический спад теперь легко объясняется” санкциями. Оно так и есть, но просто нападать не нужно было и не было бы санкций.",
        p13: "Но если не нападать, то как объяснить отсутсвие уровня жизни, собственных производств и инновационных технологий? Конечно, где-то можно было увидеть “производство полного цикла”. Но при этом механизмы привозили из Европы, комплектующие из Китая, клеили лейбл “сделано в России” и получалось, что сделано в России, верно?!",
        p14: "И было бы смешно, если бы не было так грустно.",
        p16: "Сейчас благодаря руководству сложилась такая ситуация, что в стране есть две точки зрения: государственная и неправильная. Если ты не поддерживаешь военное вторжение(спецоперацию), то при наличии аудитории ты обязательно иноагент. Юрий Дудь, который просветил всех насчет смертельной болезни(спида) - иноагент. Он брал интервью у тех, у кого он считает важным это делать. Что с этим не так?! Ни слова против власти не сказал, но звание заработал. Семен Слепаков, который обратился к президенту публично с одним вопросом, никого не оскорбил, теперь иноагент и покинул страну. Алла Пугачева – эпоха, покинула страну. Максим Галкин, Андрей Макаревич, Noize MC, Земфира, Алексей пивоваров, Артур Смолянинов, …",
        p17: "Так вот просыпаешься утром и такое ощущение, что какой-то дурной сон: война с Украиной, все твои герои иноагенты, забирают на войну ограниченно годных айтишников, запад – враг. Сюда следует еще добавить, что ты сам по себе дебил и тогда все сошлось бы. И вот идешь, пьешь кофе, постепенно приходит уверенность, что это не сон. В этот момент сильно хочется все же проснуться. И вроде, город тот же, люди за окном те же, погода таже, визуально тот же самый президент, что был 10 лет назад, но это другая страна!",
        p18: "Не вежливо кого-либо бесконечно хаить, но я должен написать про повышение пенсионного возраста. Кивнули в сторону того, что на запад равняемся. Существенное отличие в том, что, например, во Франции средняя продолжительность жизни у мужчин – 80 лет, а в России – 63-65. Получается в России ты делаешь пенсионные отчисления, которые тебе не светят. Другое сравнение. ОАЭ на доходы от нефти каждому своему резиденту покупает жилье(обычно дом) и оплачивает образование. А если есть желание и способности, то оплачивает образование за границей. Как-то вот очень контрастно: помогать деньгами своим гражданам во всем основном(ОАЭ) или отбирать пенсии у предпенсионеров(Россия). В обеих странах основной источник дохода – это нефтедобыча. В ОАЭ, правда, сейчас туризм лидирует, но это уже детали. Страна поднялась на нефти. А Россия и сегодня на ней стоит. Точнее сегодня на нефти стоит только небольшая группа российских правительственных и околоправительственных лиц и их семей.",
        p19: "Получается, что последние тренды в России: отобрать денежку у стареющих людей предпенсионного возраста и отжать земельку военным путем у братской страны, которая поменьше. Такие тренды соответствуют твоим жизненным ценностям, ценностям твоих родных и друзей? И все это происходит в то время, когда в IT больше денег, чем во всей нефтедобывающей отрасли вместе взятой. Яндекс пусть и зародилась, как реплика Гугла, но еще вчера была крутой, развитой компанией. Нужно бы сегодня всем идти в айти, а вместо этого был предложен принудительный завоевательный поход на украинские земли, уже унесший жизни огромного количества людей. Вот такие неразумные, противоестественные действия были “предложены” жителю культурно богатой, прекрасной, огромной страны.",
        p20: "Самых несогласных сажают в тюрьму. На частично несогласных производится травля любыми способами на усмотрение исполнителей. Есть несколько вопросов. У исполнителей есть дети? В какой стране они будут жить? Или им сейчас просто приятно думать, что на их детей эти же методы не применят?",
        p21: "И было бы смешно, если бы не было так грустно.",
      }
  return (
    <div>
      <Navigation />
      <div className="container" style={{marginBottom:"50px"}}>
        <h4 style={{textAlign:"center", marginTop:"10px"}}>
            {year2022Header}
        </h4>
        {Object.keys(year2022Description).map((item) => (
            <p style={{margin:"0", textIndent: "40px"}}>
                {year2022Description[item]}
            </p>
        ))}
      </div>
    </div>
  );
}
