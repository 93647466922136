import React from 'react';
import Navigation from '../components/Navigation';
import { useSelector } from 'react-redux';

import YaMap from '../components/YaMap';

export default function Location() {
  const languageChanger = useSelector((state) => state.language);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      <Navigation />
      <div style={{ height: '100vh' }}>
        <YaMap languageChanger={languageChanger} />
      </div>
    </div>
  );
}
