const initialState = {
    language: 'Eng',
    // language: 'Ru',
    menuBackgroundColor: '#F5F5F5',
    // menuBackgroundColor: '#e3f2fd',
  };

 function reducer(state = initialState, action) {

    switch (action.type) {

        case 'CHANGE_LANGUAGE':
            return { ...state,
                language: action.payload.language,
                menuBackgroundColor: action.payload.color,
            }
      default:
        return state;
    }
  }

export default reducer;