import React from 'react'
import Card from 'react-bootstrap/Card';

function lastWordIndex(string, startFrom = 0) {
  if (typeof string !== 'string') {
    return startFrom;
  }
  for (let i = startFrom; i < string.length; i++) {
    if (string[i] === ' ' || string[i] === ',' || string[i] === '.') {
      return i;
    }
  }
  return startFrom;
}

function Card_({ company }) {
  return (
    <div>
      <Card
        className="p-3 flex-fill companies-scale"
        style={{
          backgroundColor: 'inherit',
          margin: '10px',
          height: '95%',
          dislay: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
        <a
          href={company.link}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <Card.Img variant="top" src={company.logo} />
          <Card.Body>
            <Card.Text>
              {company.description.length > 150
                ? company.description.slice(0, lastWordIndex(company.description, 150)) + ' ...'
                : company.description}
            </Card.Text>
          </Card.Body>
        </a>
        <Card.Footer className="text-muted text-center">{company.type}</Card.Footer>
      </Card>
    </div>
  );
}

export default Card_;
