import React from 'react'
import Navigation from '../components/Navigation';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';


const styles = {
  main: {
    color: '#808080',
  },
  content: {
    margin: '0 auto',
    maxWidth: '30rem',
    marginBottom: '1rem',
  },
  mainHeader: {
    margin: '1rem 0 1rem',
    textAlign: 'center',
  },
  header: {
    textAlign: 'center',
  },
  col: {
    paddingTop: '1.5rem',
    maxWidth: '250px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #bdbebd',
    borderRadius: '5px',
    margin: '1rem',
  },
  link: {
    height: '100%',
    width: '100%'
  },
  text: {
    width: '100%',
    textAlign: 'center'
  }
};

export default function WebApps() {
  const languageChanger = useSelector((state) => state.language);
  const mainHeader = languageChanger !== 'Eng' ? 'GitHub repositories' : 'Репозитории GitHub';

  const projectDescription =
    languageChanger !== 'Eng'
      ? {
          'Yatube': 'Social network, Blog. The service created as a platform with functionality of publishing articles and leaving comments. Options of joining groups and subscription to the authors are provided.',
          'AssistantBot': 'Assistant bot polls an appropriate API service every 10 minutes and check for a status of previously sent work. In case of updates, helper informs the host about it through the Telegram.',
          'YamdbApi': 'Programming interface for collecting reviews and rates of works sorted by categories. The service includes 4 access levels: anonim, user, moderator, and admin.',
          'Frontube': 'Platform for articles, blog. Service affords to publish, edit, and remove illustrated articles. Access to previously pusblished articles is provided right "out of the box".',
        }
      : {
          'Yatube': 'Социальная сеть, блог. Сервис создан, как платформа с возможностью размещения постов и комментариев к ним. Пользователю доступна опция вступления в группу и подписки на других авторов.',
          'AssistantBot': 'Бот-ассистент опрашивает соответствующий API сервис раз в 10 минут и проверяет статус ранее отправленной работы. В случае обновления статуса помощник уведомляет об этом в Telegram.',
          'YamdbApi': 'Программный интерфейс для сбора отзывов и составления рейтингов произведений по категориям. Сервис предусматривает 4 уровня идентификации пользователей: аноним, юзер, модератор и админ.',
          'Frontube': 'Платформа для публикаций, блог. Сервис включает в себя возможность размещения, редактирования и удаления статей с иллюстрациями. Доступ к ранее размещенным постам предоставляется "из коробки".',
        };
        
  const projectLink = {
          'Yatube': 'https://github.com/andrey-kolesnik-moscow/Yatube',
          'AssistantBot': 'https://github.com/andrey-kolesnik-moscow/Assistant_bot',
          'YamdbApi': 'https://github.com/andrey-kolesnik-moscow/Yamdb_API',
          'Frontube': 'https://github.com/andrey-kolesnik-moscow/Frontube',
  }
  return (
    <div style={styles.main}>
      <Navigation />
      <div style={styles.content}>
        <Typography style={styles.mainHeader} className="headlines" variant="h5" gutterBottom>
          {mainHeader}
        </Typography>
        <Container fluid="xs">
          <Row className="row-flex">
            <Col className="web-apps-scale" style={styles.col}>
              <h5 style={styles.header}>
                "Yatube"
              </h5>
              <p style={styles.text}>{projectDescription.Yatube}</p>
              <a href={projectLink.Yatube} rel="noreferrer" target="_blank" class="stretched-link"></a>
            </Col>
            <Col className="web-apps-scale" style={styles.col}>
              <h5 style={styles.header}>
                "Frontube"
              </h5>
              <p style={styles.text}>{projectDescription.Frontube}</p>
              <a href={projectLink.Frontube} rel="noreferrer" target="_blank" class="stretched-link"></a>
            </Col>
          </Row>
          <Row className="row-flex">
            <Col className="web-apps-scale" style={styles.col}>
              <h5 style={styles.header}>
                "Yamdb API"
              </h5>
              <p style={styles.text}>{projectDescription.YamdbApi}</p>
              <a href={projectLink.YamdbApi} rel="noreferrer" target="_blank" class="stretched-link"></a>
            </Col>
            <Col className="web-apps-scale" style={styles.col}>
              <h5 style={styles.header}>
                "Telegram Bot"
              </h5>
              <p style={styles.text}>{projectDescription.AssistantBot}</p>
              <a href={projectLink.AssistantBot} rel="noreferrer" target="_blank" class="stretched-link"></a>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
