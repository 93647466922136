import reducer from '../redux/reducer';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import axios from 'axios';

// export const store = createStore(reducer);

export const store = createStore(
    reducer, 
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(thunkMiddleware),
    );

export const changeLanguage = (language, color) => ({
            type: 'CHANGE_LANGUAGE',
            payload: {
                language,
                color,
            },
        })

export const thunkCreator = (feedbackData) => {
    return (dispatch) => {
        try {
            const apiUrl = `https://andrey-kolesnik.com/sendEmailAndNotToBeHacked.php`;
            axios.post(apiUrl, feedbackData).then(({data}) => {
                alert(`Your message was successfully send to the server!`);
            });
        } catch {
            alert('Post request has failed! Please, try again!');
            }
    }
}