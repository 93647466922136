import React from 'react'
import Navigation from '../components/Navigation';
import { useSelector } from 'react-redux';

// languageChanger == 'Ru'

export default function WhatAndHow() {
  const languageChanger = useSelector((state) => state.language);
  const content = 
    languageChanger !== 'Eng'
    ?(
      <div>
        <Navigation />
        <div className="container" style={{marginBottom:"50px"}}>
            <h4 style={{textAlign:"center", marginTop:"10px"}}>
              Russia has two paths. How to be and what to do?
            </h4>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
              Disclaimer. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
              This article is not an unconditional guide to action. I hope that you are over 21 and capable of critical thinking, which is very important nowadays. Let's just try to contemplate together about the present and the future.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            What does Russia have at the end of 2023? 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - The war with Ukraine, which in two years has crippled and killed over half a million Russian-speaking people.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Totalitarianism or authoritarianism in the country as a whole instead of pseudo-democracy, which was proposed until 2014 by the permanent "president" of Russia. The precise definition of the current structure of the state is currently being discussed in scientific circles.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Lack of communication with the developed outside world. That is, today any Russian is not very welcome in the West in any capacity, except for a few countries.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Physical isolation of the country, when planes do not fly to many countries anymore, and Russians are much more restricted in visas.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Meager salary for the largest part of the population with the only opportunity to make money in war and with the prospect of becoming a murderer, as well as with a great risk of losing one's life or limbs.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Lack of warm toilets in a large part of the country.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Reduction of social programs.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Pocket-crushing inflation.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - An unprecedented increase in the cost of all cars over the past 5 years by 2-5 times.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Those who disagree with the regime in Russia are being destroyed. The executors are the FSB and Federal Penitentiary Service. It would seem that these structures should resist external threats and terrorism as well as interdiction of criminals. Instead, they kill and imprison people for having their own opinions and views.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Almost all those who disagreed, the most able-bodied and self-confident people had to go abroad.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            What's in return?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Constantly shelled Crimea, Donetsk, Luhansk and Zaporozhsky regions of Ukraine, as well as the border regions of Russia and, less frequently, its central part.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Is all this normal?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Having two higher educations, a decent biography, knowing two languages, listing everything above, the only thing that comes to my mind is: That's what they did! (bad words have been removed from the statement before publication)
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Population and Elite in Russia.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            The goal in the life of an ordinary person is development and prosperity. That is fine. Why does Putin and his team hate Russians so much? Why did they lead everyone down the path of degradation and poverty? Obviously, only for the sake of enriching a small group of people who are part of their gang. Winding 140 million people round their little finger, deceiving so huge number of citizens, they will certainly go down in history. But isn't it cruel to derail the entire country, expel more than a million and kill more than 500,000 people for the sake of their own enrichment? They behave as cannibals. Cannibalism without eating flesh is also cannibalism. Do you agree with that?
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            What do the countries of the East think?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            China remains neutral as Russia brings in its money. India is happy to buy oil for non-convertible rupees and is neutral. Turkey is playing its card, seizing the moment, but is neutral. UAE also keeps neutrality.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            What should a resident think?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            And here, we seem to feel pride that so many countries have such a neutral position regarding Russia. But there is no reason to rejoice because their neutrality has nothing to do with Russia. Their neutrality relates to the money coming from oil and gas that Russia is throwing to the right and to the left instead of subsidizing your child's education. Are you getting anything? I don't mean "on the head", but money for development. You will say: it has always been like this. And you'd be right. But if you continue to talk the same way and do nothing, it will always be so. It’s foolish to expect the same action to lead to different results.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            What does the West think?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            An undemocratic regime is being formed in Ukraine. The sign of that is cancellation of presidential elections. A high level of corruption is rampant. Ukraine's allies are a little tired of funding, but they do not see any other way out or have not yet found it.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            What should a resident do?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Do not let Putin and team engage in lawlessness indefinitely.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            How?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Not to strengthen the economy of a country that works for criminal purposes. Why support the feudal lords of the 21st century who follow the path of threats, violence, and murder?! Sounds like sabotage, right?! Yes, but there is no other way. Until every Russian stands up for their future and the future of their country, nothing will fundamentally change.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            How to do it?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Rely on your selfish interests. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            If you're an oligarch or a successful entrepreneur, you know what to do and am not the one to advise you. Please, write in the feedback where did you get the link my site. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            If you have enough money, for example, for an apartment in Thailand or Bali, then feel free to transfer them to crypto and withdraw them in the country you have chosen for Investment. Everything will work out! Of course, assess the risks sensibly, but the benefits will definitely be greater than similar investments in Russia. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            If you don't have enough money for an apartment abroad, then invest in your education or children education. Preferably abroad. Online education is a great way. Has your internet been blocked? - No problem. A VPN service is a great solution. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            If you are as poor as a church mouse, then try to get more, not allowing others to exploit you. With the vertical of power and the country's raw material economy, you will succeed. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Don't innovate in any way. You won't get paid for them. In the existing structure of the state only the skills of sycophancy and the ability to tear off a piece of the state are valuable, not your bright ideas. Innovations for military purposes may be paid for. But even so, you most likly don't want to to be involved in the deaths of our brotherly people and our own one.  You can say, "Why your own?" Because any innovation will be quickly borrowed and used against you and all the people.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            What to do if everything seems to be normal?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Try to improve the level of education, read and study. When studying the materials, for example, i always do this: I take facts that are confirmed in different sources, skip the agitation block and form my own conclusions. Saying difference sources i don't mean two different Telegram channels that have the same owner. They still can be two Telegram channels, but one is Russian and the other is Ukrainian. Even better is to get two or three sources researched from both sides, then the sample is more representative.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Conclusion and a few statements.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - It is very sad that the Russian people are being taught to be afraid again. You should be afraid of Putin, Kadyrov, Patrushev and a huge crowd of government and near-government officials. It seemed that we had just begun to live normally, some 10 years ago. And now, all of a sudden, we are being drawn back into the same swamp. But either back to the "cheap" analogue of the Soviet Union without ideology, or completely in the Middle Ages with serfdom and slave morals.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Russia needs a change of elites. Elites in a well-organized and forward-looking society do not ignore the opinion of dissenters, who are supported by a significant part of the population. They often make concessions to them. And even more so, they are not engaged in the genocide of people who disagree with the authorities.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Physical destruction of dissenters in any way is abnormal.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - After Putin, it is likely to be worse, but it will not be forever bad. Things are unlikely to change quickly, rather in stages.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Russia has "two paths" and it will get out of the nosedive into which it was driven by Putin and team. But if it comes out of the nosedive in 5-10 years – that's one way. If in a few generations - it's a very different way. What will happen in the country depends on you and the direction of your efforts.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            I call everyone to humanity and common sense. Take care of yourself and those you love.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Good luck to everyone, huggs!
            </p>
            <p style={{textAlign:"right", margin:"0", textIndent: "40px"}}>
            Publication date: December 20, 2023.
            </p>
          </div>
      </div>
    )
    :(
      <div>
        <Navigation />
          <div className="container" style={{marginBottom:"50px"}}>
            <h4 style={{textAlign:"center", marginTop:"10px"}}>
              У России два пути. Как быть и что делать?
            </h4>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Дисклеймер. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Данная статья не является безоговорочным руководством к действию. Я надеюсь, что вам больше 21 и вы способны к критическому мышлению, что очень важно в наше время. Давайте просто попробуем вместе порассуждать над настоящим и будущим.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Что есть в России на конец 2023 года? 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Война с Украиной, которая за два года покалечила и убила от полумиллиона русскоговорящих людей. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Тоталитаризм или авторитаризм в стране в целом взамен псевдодемократии, которая предлагалась до 2014 года бессменным “президентом” России. О точном определении современного устройства государства сейчас идут дискуссии в научных кругах.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Отсутствие коммуникации с развитым внешним миром. То есть каждый русский сегодня не очень welcome на западе в любом качестве, за исключением нескольких стран.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Физическая изоляция страны, когда самолеты не летают и русских за границу не пускают.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Нищенские зарплаты для наибольшей части населения с единственной возможностью заработать на войне и с перспективой стать убийцей, а также с большим риском лишиться жизни или конечностей.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Отсутствие теплых туалетов в значительной части уголков страны.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Сокращение социальных программ.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Бьющая по карману инфляция.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Безмерное удорожание всех машин за последние 5 лет в 2 – 5 раз.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Оставшихся несогласных с режимом в России уничтожают. Исполнители – ФСБ и ФСИН. Эти структуры, казалось бы, должны противостоять внешним угрозам и терроризму. Вместо этого они убивают и сажают в тюрьмы людей за обладание своим собственным мнением и взглядами.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - За границу пришлось уехать почти всем несогласным, самым работоспособным и уверенным в себе людям. 
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Что взамен? 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Постоянно обстреливаемый Крым, Донецкая, Луганская и Запорожская области Украины, а также приграничные области России и, реже, ее центральная часть.   
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Нормально ли все это?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Имея два высших образования, достойную биографию, два языка в своем арсенале и перечислив все выше, в голову приходит только: Вот это они сделали! (нецензурная лексика удалена из высказывания перед публикацией).
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Население и элита в России.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Цель в жизни обычного человека – это развитие и процветание. Это нормально. За что Путин со своей командой так ненавидит русских? Зачем они повели всех по пути деградации и нищеты? Очевидно, что только ради обогащения небольшой группы лиц, входящих в состав их группировки. Обведя вокруг пальца 140 млн., конечно, они войдут в историю. Но не люто ли пустить всю страну под откос, выдворить из нее больше миллиона и убить более 500 тысяч человек ради собственного обогащения? Как-то по-людоедски. Каннибализм без поедания плоти тоже каннибализм. Вы согласны? 
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Что думают страны востока? 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Китай сохраняет нейтралитет, так как Россия приносит свои деньги. Индия с удовольствием покупает нефть за неконвертируемые в другую валюту рупии и нейтральна. Турция разыгривает свою карту, воспользовавшись моментом, но нейтральна. ОАЭ – нейтралитет.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Что думать резиденту?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            И тут, казалось бы просыпается гордость за то, что к России столько стран имеют нейтралитет в текущих условиях. Но нет повода радоваться, потому что не к России они имеют нейтралитет. К деньгам от нефти и газа, которые Россия раскидывает налево и направо, вместо того, чтобы субсидировать в образование именно вашего ребенка. Конкретно вам сейчас что-то прилетает? Я имею ввиду не “по шапке”, а деньги на развитие. Скажете: всегда так было. И будете правы. Но если дальше также говорить и ничего не делать, то всегда так будет. Глупо рассчитывать на то, что одно и то же действие приведет к разным результатам.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Что думает Запад?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            В Украине формируется недемократический режим(отмена выборов президента) и до сих пор процветает большой уровень коррупции. Союзники Украины слегка подустали от финансирования, но другого выхода не видят или пока не нашли.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Что делать резиденту? 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Не давать Putin and team заниматься беспределом бесконечно долго.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Как? 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Не укреплять экономику страны, которая работает в преступных целях. Зачем поддерживать феодалов 21-го века, которые идут путем угроз, насилия и убийств?! Звучит как саботаж, верно?! Да, но другого пути нет. Пока каждый россиянин не встанет на защиту своего будущего и будущего своей страны, ничего основательно не поменяется.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Как осуществить?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Опираться на свои “шкурные” интересы.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Если ты Олигарх или успешный предприниматель, то сам знаешь, что делать и я тебе не советчик. Напиши, пожалуйста, в форму обратной связи, откуда зашел на мой сайт. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Если “поскрести по сусекам” и денег у вас хватает, например, на квартиру в Тайланде или Бали, то смело переводите их в крипту и выводите в той стране, которую выбрали для инвестиции. Все получится! Конечно, здраво оценивайте риски, но выгода будет точно больше и точно будет, в отличие от аналогичных инвестиций в России. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Если на квартиру за границей не хватает, то инвестируйте в свое образование и образование своих детей. Желательно за границей. Онлайн – отличный способ. Заблокировали? - не беда. VPN - отличное решение. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Если ты “гол, как сокол”, то старайся получить больше, не давая “на себе ездить”. С вертикалью власти и сырьевой экономикой страны у тебя это получится.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Не вводите никаких инноваций. Вам за них не заплатят. В существующем устройстве государства ценны только навыки подхалимства и умения оторвать кусок, а не ваши светлые идеи. За инновации в военных целях, может, заплатят. Но даже если так, то вряд ли вам хочется быть причастными к множеству смертей братского и своего народа. Скажете “почему своего?” Потому что любую инновацию достаточно быстро позаимствуют и применят именно против вас и всего народа.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Что делать, если кажется, что все происходящее норм?
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Повышать уровень образования: читать, изучать. При изучении материалов я, например, всегда делаю так: беру факты, подтверждаемые в разных источниках и с разных сторон, пропускаю мимо агитационный блок и формирую свои собственные выводы. Разные источники имеется ввиду не два разных telegram канала, у которых один хозяин, а, например, так же два telegram канала, но один – российский, а другой – украинский. Еще лучше – по два-три источника с разных сторон, тогда выборка более репрезентативна.
            </p>
            <p style={{textAlign:"center", margin:"0", textIndent: "40px"}}>
            Итоги и несколько утверждений.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Очень печально, что русский народ обратно приучают бояться. Нужно бояться Путина, Кадырова, Патрушева и еще огромную толпу правительственных и околоправительственных деятелей. Казалось, только вроде бы жить нормально начинали, еще каких-то 10 лет назад. А теперь, вдруг, обратно в тот же омут. Но то ли обратно в “дешевый” аналог Советского Союза без идеологии, то ли совсем в средневековье с крепостным правом и рабскими нравами. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - России нужна смена элит. Элиты в хорошо организованном и перспективном обществе не игнорируют мнение несогласных, которых поддерживает ощутимая часть населения. Часто идут им на уступки. И уж тем более не занимаются геноцидом несогласных с властью людей. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - Физическое уничтожение несогласных любым способом ненормально.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - После Путина скорее всего будет хуже, но это не навсегда. Вряд ли все поменяется быстро, скорее поэтапно. 
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            - У России “два пути” и она выйдет из пике, в которое ее загнал Putin and team. Но если она выйдет из пике через 5-10 лет – это один путь. А если через несколько поколений – это совсем другой путь. Что будет в стране зависит именно от тебя и от направления твоих усилий.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Призываю каждого к человечности и здравому смыслу. Берегите себя.
            </p>
            <p style={{margin:"0", textIndent: "40px"}}>
            Всем удачи! Всех обнял, приподнял.
            </p>
            <p style={{textAlign:"right", margin:"0", textIndent: "40px"}}>
            Дата публикации: 20 декабря 2023 года.
            </p>
          </div>
      </div>
    )

  return content
}
