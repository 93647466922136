import React from 'react';
import { YMaps, Map } from 'react-yandex-maps';
import { Card, Button } from 'react-bootstrap';
import YaMapImitation from '../images/YaMapImitation.jpg';

export default function YaMap({ languageChanger }) {
  const [yaDownloadIsOk, setYaDownLoadIsOk] = React.useState(true);
  const lang = languageChanger === 'Ru' ? 'en-US' : 'ru-RU';
  const handleError = (e) => {
    setYaDownLoadIsOk(false);
  };
  return (
    <div>
      <YMaps
        key={lang + yaDownloadIsOk}
        query={{
          lang: lang,
        }}>
        <Map
          defaultState={{ center: [55.75, 37.57], zoom: 11 }}
          width="100%"
          height="97vh"
          onError={(e) => handleError()}
        />
      </YMaps>
      {!yaDownloadIsOk && (
        <Card style={{ margin: '3rem auto 0', width: '14rem', backgroundColor: '#edffec' }}>
          <Card.Body>
            <Card.Text>
              YaMap can't be downloaded. Please check the internet connection and try again.
            </Card.Text>
            <Card.Img variant="top" src={YaMapImitation} />
            <Button
              variant={languageChanger === 'Eng' ? 'outline-success' : 'outline-primary'}
              className="mt-3"
              onClick={() => setYaDownLoadIsOk(true)}>
              Try again
            </Button>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
