import React from 'react'
import Navigation from '../components/Navigation';
import Slider from '../components/Carousel';
import { Container, Row, Col } from 'react-bootstrap';

export default function Lifestyle() {
  return (
    <div>
      <Navigation />
      <Container>
        <Row>
          <Col md={8} style={{ margin: '2rem auto' }}>
            <Slider />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
