import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { thunkCreator } from '../redux/actions';
import { Button } from 'react-bootstrap';
// import Recaptcha from 'react-google-recaptcha';

import FeedbackInput from '../components/FeedbackInput';
import Navigation from '../components/Navigation';

function makeStyles() {
  return {
    formWithText: {
      maxWidth: '40rem',
      margin: '0 auto',
      padding: '0 10px',
    },
    header: {
      textAlign: 'center',
      margin: '1rem 0',
      color: '#808080',
    },
    form: {
      marginTop: '1rem',
    },
    button: {
      padding: '8px 22px',
      margin: '30px 0 30px 10px',
      float: 'right',
    },
  };
}

export default function FeedbackForm() {
  const styles = makeStyles();
  const languageChanger = useSelector((state) => state.language);
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  const recaptchaRef = React.useRef();
  const [verified, setVerified] = React.useState(false);
  const [Recaptcha, setRecaptcha] = React.useState(null);

  const form =
    languageChanger !== 'Eng'
      ? {
          header: 'Please fill in the form',
          name: 'Name',
          companyName: 'Company name',
          email: 'E-mail',
          phone: 'Phone',
          message: 'Your awesome message',
          button: 'Submit',
        }
      : {
          header: 'Пожалуйста, заполните форму',
          name: 'Имя',
          companyName: 'Название компании',
          email: 'E-mail',
          phone: 'Телефон',
          message: 'Сообщение',
          button: 'Отправить',
        };

  const fields = [
    {
      field: 'firstName',
      label: form.name,
      required: true,
      size: 6,
      pattern: /^[(A-Za-z)|(А-Яа-я)]{2,20}$/i,
    },
    {
      field: 'companyName',
      label: form.companyName,
      required: false,
      size: 6,
      pattern: /^[\w\W]{2,20}$/i,
    },
    {
      field: 'email',
      label: form.email,
      required: true,
      size: 6,
      pattern:
        /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i,
    },
    {
      field: 'phone',
      label: form.phone,
      required: true,
      size: 6,
      pattern: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/i,
    },
    {
      field: 'textfield',
      label: form.message,
      required: true,
      size: 12,
      rows: 4,
      pattern: /^[\w\W]{2,500}$/i,
    },
  ];

  const onSubmit = (data, e) => {
    if (verified) {
      e.target.reset();
      recaptchaRef.current.reset();
      setVerified((res) => !res);
      const postData = thunkCreator(JSON.stringify(data));
      postData(dispatch);
    }
  };

  function onChange() {
    setVerified((res) => !res);
  }

  React.useEffect(() => {
    if (!Recaptcha) {
      import('react-google-recaptcha').then((module) => {
        setRecaptcha((value) => module.default);
      });
    }
  }, [Recaptcha]);

  React.useEffect(() => {
    setRecaptcha(null);
    setVerified(false);
  }, [languageChanger]);

  return (
    <div>
      <Navigation />
      <div style={styles.formWithText}>
        <Typography style={styles.header} className="headlines" variant="h5" gutterBottom>
          {form.header}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={styles.form}>
          <Grid container spacing={3}>
            {fields.map((item) => (
              <FeedbackInput
                item={item}
                key={item.field}
                register={register}
                error={errors[item.field]}
                required={item.required}
              />
            ))}
          </Grid>
          {Recaptcha && (
            <Recaptcha
              className="recaptcha"
              ref={recaptchaRef}
              hl={languageChanger !== 'Eng' ? 'en' : 'ru'}
              sitekey="6LeIfsUaAAAAAIVAj-v0x9noCiG1Pn6a2bjTcIRM"
              // sitekey="6Lchd8UaAAAAAG-nEVhFRrnAlgGxCmk0daBgWwh3"
              onChange={onChange}
            />
          )}
          <Button
            variant={languageChanger === 'Eng' ? 'outline-dark' : 'outline-primary'}
            style={styles.button}
            onClick={(e) => e.currentTarget.blur()}
            type="submit">
            {form.button}
          </Button>
        </form>
      </div>
    </div>
  );
}
