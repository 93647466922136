import React from 'react'
import Navigation from '../components/Navigation';
import mainMobileJPG from '../images/main_img_mobile.jpg';
import mainPCJPG from '../images/main_img_pc.jpg';
import { useSelector } from 'react-redux';

export default function HomePage() {
  const languageChanger = useSelector((state) => state.language);
  const borderColor = useSelector((state) => state.menuBackgroundColor);

  const slogan =
    languageChanger !== 'Eng'
      ? {
          firstPart: 'Good engineering is not about the most in-depth knowledge in all the areas',
          secondPart:
            'but it is about problem solving and finding the best solutions on the daily basis.',
          entireSlogan:
            'Good engineering is not about the most in-depth knowledge in all the areas, but it is about problem solving and finding the best solutions on the daily basis.',
        }
      : {
          firstPart: 'Качественная инженерия – это не о самых глубоких знаниях во всех областях',
          secondPart: 'а о решении проблем и ежедневном нахождении наилучших решений.',
          entireSlogan:
            'Качественная инженерия – это не о самых глубоких знаниях во всех областях, а о решении проблем и ежедневном нахождении наилучших решений.',
        };

  return (
    <div>
      <Navigation />
      <div className="main-block-mobile">
        <div
          style={{
            backgroundColor: '#fff',
            width: '100%',
            height: '18.25vw',
            padding: '2vw 4vw',
          }}>
          <p className="streching-mobile-text" style={{ float: 'left', color: '#5a6fb2' }}>
            {slogan.firstPart}
          </p>
        </div>
        <img className="d-block img-fluid" src={mainMobileJPG} alt="" style={{ width: '100%' }} />
        <div
          style={{
            backgroundColor: '#5a6fb2',
            width: '100%',
            color: '#fff',
            height: '18.25vw',
            padding: '2vw 4vw',
          }}>
          <p className="streching-mobile-text" style={{ float: 'right', textAlign: 'right' }}>
            {slogan.secondPart}
          </p>
        </div>
      </div>

      <div className="main-block-pc">
        <img className="d-block img-fluid" src={mainPCJPG} alt="" style={{ width: '75%' }} />
        <div
          className="div-over-strechingtext"
          style={{
            borderLeft: `8px solid ${borderColor}`,
            borderRight: `8px solid ${borderColor}`,
          }}>
          <p className="streching-pc-text">{slogan.entireSlogan}</p>
        </div>
      </div>
    </div>
  );
}
