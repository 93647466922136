import React from 'react'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

function FeedbackInput({ item, register, defaultValue, error, required }) {
  return (
    <Grid item xs={item.size}>
      <TextField
        name={item.field}
        defaultValue={defaultValue}
        label={item.label}
        inputRef={register({ required: required, pattern: item.pattern })}
        style={{ width: '100%', color: 'red' }}
        variant="outlined"
        rows={4}
        multiline={item.rows ? true : false}
        error={error ? true : false}
        helperText={
          !error
            ? ''
            : error.type === 'required'
            ? 'This field is required'
            : `Please type your real ${item.label}`
        }
      />
    </Grid>
  );
}

export default FeedbackInput;
