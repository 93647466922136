import React from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { render } from 'react-snapshot';

import 'bootstrap/dist/css/bootstrap.min.css';

import { store } from './redux/actions';
import { Provider } from 'react-redux';

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//       <Provider store={store}>
//           <App />
//       </Provider>
//    </React.StrictMode>,
//   document.getElementById('root')
// );

// const rootElement = document.getElementById('root');

// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(
//     <React.StrictMode>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </React.StrictMode>,
//     rootElement,
//   );
// } else {
//   ReactDOM.render(
//     <React.StrictMode>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </React.StrictMode>,
//     rootElement,
//   );
// }
