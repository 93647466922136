import React from 'react'

import Navigation from '../components/Navigation';
import { useSelector } from 'react-redux';
import Card from '../components/Card';

import mevaLogo from '../images/logos/Meva.png';
import periLogo from '../images/logos/Peri.png';
import renaissanceConstructionLogo from '../images/logos/Renaissance construction.png';
import kbsmLogo from '../images/logos/КБСМ.png';
import laborSignLogo from '../images/logos/Знамя труда.png';
import kmzLogo from '../images/logos/КМЗ.png';

export default function Companies() {
  const languageChanger = useSelector((state) => state.language);
  const companies =
    languageChanger !== 'Eng'
      ? {
          meva: {
            logo: mevaLogo,
            description:
              "MEVA is a family owned and managed manufacturer of formwork technology with an international profile. We supply the contractor community from 40 locations across the world. Our home is in Haiterbach in the northern Black Forest region in Germany's beautiful south west. In the Headquarters we do research and development, production, logistics, engineering, sales and international sales business support.",
            link: 'https://www.meva.net/',
            type: 'Construction company',
          },
          peri: {
            logo: periLogo,
            description:
              "PERI is one of the world's leading suppliers of formwork and scaffolding systems, civil engineering solutions and leading 3D construction printing supplier. Since its foundation in 1969, the PERI headquarters in Weißenhorn, Germany, has been steering the fortunes of the globally operating family business.",
            link: 'https://www.peri.ru/',
            type: 'Construction company',
          },
          renaissanceConstruction: {
            logo: renaissanceConstructionLogo,
            description:
              'Renaissance Construction was founded  in St. Petersburg in 1993. Renaissance specializes in design and construction of shopping centers, offices, hotels, multifunctional complexes, high-rise buildings, heavy industrial facilities (cement plants, construction machinery and auto plants, chemical and petrochemical plants, fertilizer plants, metallurgical plants, etc.), infrastructure (airports, tunnels, marinas, etc.), energy facilities, pharmaceutical plants, food and beverage processing plants, public buildings, residential buildings, etc.',
            link: 'https://rencons.com/',
            type: 'Construction company',
          },
          kbsm: {
            logo: kbsmLogo,
            description:
              'KBSM is multi-profile dynamically developing company possessing all the types of technologies and equipment for the design and creation of high-tech and science-intensive products in the industry.',
            link: 'http://xn--90arht.xn--p1ai/',
            type: 'Heavy industry company',
          },
          laborSign: {
            logo: laborSignLogo,
            description:
              "Zavod Znamya Truda CJSC is one of the world's biggest manufacturers of pipeline valves in Russia. Over the long history of its existence, the plant has accumulated extensive knowledge in the field design and implementation of complex technical projects and we are constantly working on new developments based on customer requirements.",
            link: 'http://zzt.ru/',
            type: 'Heavy industry company',
          },
          kmz: {
            logo: kmzLogo,
            description:
              'KMZ plant is a leading Russian company specializing in the production of elevator equipment and it has 70-year history. We are proud that we are not just a company that manufactures and sells elevators, but we are a huge manufacturing enterprise with a rich history and glorious traditions.',
            link: 'https://www.kmzlift.ru/',
            type: 'Heavy industry company',
          },
        }
      : {
          meva: {
            logo: mevaLogo,
            description:
              'Компания МЕВА - это семейный бизнес по производству опалубки с международным именем. Мы снабжаем подрядчиков из 40 точек по всему миру. Наша компания находится в Хайтербахе, в северной части региона Шварцвальд, на красивом юго-западе Германии. В головном офисе мы занимаемся исследованиями и разработками, производством, логистикой, инжинирингом, продажами и поддержкой бизнеса по международным продажам.',
            link: 'https://www.meva.net/',
            type: 'Строительная компания',
          },
          peri: {
            logo: periLogo,
            description:
              'Компания PERI - один из мировых лидеров по поставкам опалубки и строительных лесов, инженерных решений и 3-D печати в строительстве. С момента своего основания в 1969 году штаб-квартира PERI в Вайсенхорне, Германия, управляла состоянием семеного бизнеса международного масштаба.',
            link: 'https://www.peri.ru/',
            type: 'Строительная компания',
          },
          renaissanceConstruction: {
            logo: renaissanceConstructionLogo,
            description:
              '«Ренейссанс Констракшн» — международная строительная компания, основанная  в 1993 г. в Санкт-Петербурге.              «Ренейссанс Констракшн» предлагает полный комплекс услуг в области проектирования и строительства торговых и бизнес-центров, многофункциональных комплексов, гостиниц, предприятий тяжелой, перерабатывающей промышленности, жилых зданий, объектов энергетики и инфраструктуры.',
            link: 'https://rencons.com/',
            type: 'Строительная компания',
          },
          kbsm: {
            logo: kbsmLogo,
            description:
              'АО «Конструкторское бюро специального машиностроения» - это многопрофильное динамично развивающееся предприятие, обладающее всеми видами технологий и оборудования для проектирования и создания высокотехнологичных и наукоемких изделий для  промышленности.',
            link: 'http://xn--90arht.xn--p1ai/',
            type: 'Машиностроительная компания',
          },
          laborSign: {
            logo: laborSignLogo,
            description:
              'АО «Завод «Знамя труда» — один из крупнейших производителей трубопроводной арматуры в России. За долгую историю своего существования заводом накоплены обширные знания в области конструирования и реализации сложных технических проектов и ведётся постоянная работа по новым разработкам на основе требований заказчиков.',
            link: 'http://zzt.ru/',
            type: 'Машиностроительная компания',
          },
          kmz: {
            logo: kmzLogo,
            description:
              'Завод КМЗ – ведущее российское предприятие по производству лифтового оборудования с 70-летней историей. Мы гордимся тем, что мы не просто компания, выпускающая и продающая лифты - мы огромное производственное предприятие с богатой историей и славными традициями.',
            link: 'https://www.kmzlift.ru/',
            type: 'Машиностроительная компания',
          },
        };
  return (
    <div>
      <Navigation />
      <div className="container">
        <div className="mt-4 row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-3 g-3">
          {Object.keys(companies).map((item) => (
            <Card key={item} company={companies[item]} />
          ))}
        </div>
      </div>
    </div>
  );
}
