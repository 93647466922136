import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HomePage from './pages/HomePage';
import Companies from './pages/Companies';
import WebApps from './pages/WebApps';
import WhatAndHow from './pages/WhatAndHow';
import Year2022 from './pages/Year2022';
import Location from './pages/Location';
import Lifestyle from './pages/Lifestyle';
import FeedBackForm from './pages/FeedbackForm';

import { useSelector } from 'react-redux';


function App() {
  const backgroundColor = useSelector((state) => state.menuBackgroundColor);
  document.body.style.backgroundColor = backgroundColor;
  return (
    <BrowserRouter>
        <Switch>
        {/* <Routes> */}
          {/* <Route path="/" element={HomePage} exact />
          <Route path="/companies" element={Companies} exact />
          <Route path="/webapps" element={WebApps} exact />
          <Route path="/year2022" element={Year2022} exact />
          <Route path="/location" element={Location} exact />
          <Route path="/lifestyle" element={Lifestyle} exact />
          <Route path="/feedback" element={FeedBackForm} exact /> */}
          <Route path="/" component={HomePage} exact />
          <Route path="/companies" component={Companies} exact />
          <Route path="/webapps" component={WebApps} exact />
          <Route path="/year2022" component={Year2022} exact />
          <Route path="/whatAndHow" component={WhatAndHow} exact />
          <Route path="/location" component={Location} exact />
          <Route path="/lifestyle" component={Lifestyle} exact />
          <Route path="/feedback" component={FeedBackForm} exact />
        </Switch>
        {/* </Routes> */}
    </BrowserRouter>
  );
}

export default App;
