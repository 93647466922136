import React from 'react'
import { Navbar, Nav, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from '../redux/actions';

export default function Navigation() {
  const languageChanger = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const menu =
    languageChanger !== 'Eng'
      ? {
          home: 'Home',
          companies: 'Companies',
          webApps: 'Web apps',
          yearOfWar: '2022',
          solutions2023: 'Two ways',
          location: 'Location',
          lifestyle: 'Lifestyle',
          feedback: 'Feedback',
        }
      : {
          home: 'Главная',
          companies: 'Компании',
          webApps: 'Веб',
          yearOfWar: '2022',
          solutions2023: 'Два пути',
          location: 'Локация',
          lifestyle: 'Стиль жизни',
          feedback: 'Обратная связь',
        };

  const language = languageChanger === 'Ru' ? 'Eng' : 'Ru';
  // const backgroundColor = languageChanger === 'Ru' ? '#fff' : '#e3f2fd';
  // const backgroundColor = languageChanger === 'Ru' ? '#e3dfde' : '#e3f2fd';
  // const backgroundColor = languageChanger === 'Ru' ? '#f1f1f1' : '#e3f2fd';

  // const backgroundColor = languageChanger === 'Ru' ? '#F0F8FF' : '#e3f2fd';
  const backgroundColor = languageChanger === 'Ru' ? '#F5F5F5' : '#e3f2fd';

  // const backgroundColor = languageChanger === 'Ru' ? '#F5FFFA' : '#e3f2fd';

  const handleLanguage = (e) => {
    dispatch(changeLanguage(language, backgroundColor));
    e.currentTarget.blur();
  };

  return (
    <Navbar expand="md" style={{ borderBottom: '1px solid #bdbebd' }}>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <NavLink to={{ pathname: '/' }} activeClassName="nav-link">
            {menu.home}
          </NavLink>
          <NavLink to={{ pathname: '/companies' }} className="nav-link">
            {menu.companies}
          </NavLink>
          <NavLink to={{ pathname: '/webapps' }} className="nav-link">
            {menu.webApps}
          </NavLink>
          <NavLink to={{ pathname: '/location' }} className="nav-link">
            {menu.location}
          </NavLink>
          <NavLink to={{ pathname: '/lifestyle' }} className="nav-link">
            {menu.lifestyle}
          </NavLink>
          <NavLink to={{ pathname: '/feedback' }} className="nav-link">
            {menu.feedback}
          </NavLink>
          <NavLink to={{ pathname: '/year2022' }} className="nav-link">
            {menu.yearOfWar}
          </NavLink>
          <NavLink to={{ pathname: '/whatandhow' }} className="nav-link">
            {menu.solutions2023}
          </NavLink>
        </Nav>
        <Button
          variant={languageChanger === 'Eng' ? 'outline-dark' : 'outline-primary'}
          onClick={(e) => handleLanguage(e)}>
          {languageChanger}
        </Button>
      </Navbar.Collapse>
    </Navbar>
  );
}
