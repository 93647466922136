import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import myStyle1 from '../images/my_style_1.jpg';
import myStyle2 from '../images/my_style_2.jpg';
import myStyle3 from '../images/my_style_3.jpg';
import myStyle4 from '../images/my_style_4.jpg';
import { useSelector } from 'react-redux';

const Slider = () => {
  const languageChanger = useSelector((state) => state.language);
  const backgroundColor = useSelector((state) => state.menuBackgroundColor);
  const slideHeaders =
    languageChanger !== 'Eng'
      ? {
          first: 'Bayern, Munich',
          second: 'Camping, Europe',
          third: 'Suzdal, Russia',
          fourth: 'Sharm El Sheikh, Egypt',
        }
      : {
          first: 'Мюнхен, Бавария',
          second: 'Кемпинг, Европа',
          third: 'Суздаль, Россия',
          fourth: 'Шарм-эль-Шейх, Египет',
        };
  return (
    <div
      className="corner-box-1"
      style={{ border: `2px solid ${backgroundColor}`, margin: '0 auto' }}>
      <div className="corner-textbox-1">
        <Carousel style={{ boxShadow: '20px 20px 36px rgba(0,0,0,0.2)' }}>
          <Carousel.Item animation="false" interval={1500}>
            <img className="d-block img-fluid" src={myStyle1} alt={slideHeaders.first} />
            <Carousel.Caption>
              <h3>{slideHeaders.first}</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img className="d-block img-fluid" src={myStyle2} alt={slideHeaders.second} />
            <Carousel.Caption>
              <h3>{slideHeaders.second}</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img className="d-block img-fluid" src={myStyle3} alt={slideHeaders.third} />
            <Carousel.Caption>
              <h3>{slideHeaders.third}</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img className="d-block img-fluid" src={myStyle4} alt={slideHeaders.fourth} />
            <Carousel.Caption>
              <h3>{slideHeaders.fourth}</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default Slider;
